import PocketBase from "pocketbase";
import apiUrl from "./api-url";
import axios from 'axios';

const blackApi = new PocketBase(apiUrl());

const login = async (email: string, password: string) => {
  email = email.toLowerCase();
  return await blackApi
    .collection("users")
    .authWithPassword(email, password)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};

const isLoggedIn = async () => {
  return await blackApi.authStore.isValid;
};

const getMyId = async () => {
  let id = sessionStorage.getItem("my_id");
  if (!id || id == null || id == "undefined") {
    let my_id: any = await blackApi.authStore.model?.id;
    sessionStorage.setItem("my_id", my_id);
    return my_id;
  }
  return id;
};

const createAmbulanceChecklist = async (data: any) => {
  return await blackApi
    .collection("ambulance_checklist_submission")
    .create(data)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};

const createAmbulanceForm = async (data: any) => {
  return await blackApi
    .collection("ambulance_checklist_form")
    .create(data)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
}

const resetPassword = (email: string) => {
  email = email.toLowerCase();
  return blackApi.collection("users").requestPasswordReset(email);
};

const CreateAccount = async (data: any) => {
  // console.log(data);
  const record = await blackApi.collection("users").create(data);
  // (optional) send an email verification request
  await blackApi.collection("users").requestVerification(data.email);
};

const getDepartments = async () => {
  const records = await axios.get('https://bblack.lazem.sa/api/collections/department/records', {
    headers: {
      "X-Token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkxhemVtLnNhIiwiaWF0IjoxNTE2MjM5MDIyfQ.vzES5vYBsYMl0cybgoAL6i_iv_dP7UVAdCe30qe6dtQ'
    },

  });
  return records;
};

const getSections = async () => {
  const record = await blackApi.collection("ambulance_checklist_sections").getFullList({
    expand:"section_details, section_details.subsection_question, section_details.subsection_question.dependent_question, section_details.subsection_question.dependent_question.dependent_question",
  });
  // console.log(record);
  return record;
}

const getMedicalServicesEmployees = async () => {
  const record = await blackApi.collection("users").getFullList({
    filter:'department="7rou36lgdue0mki"'
  });
  // console.log('test');
  // console.log(record);
  return record;
}


export {
  login,
  isLoggedIn,
  getMyId,
  createAmbulanceChecklist,
  resetPassword,
  CreateAccount,
  getDepartments,
  getSections,
  createAmbulanceForm,
  getMedicalServicesEmployees
};
