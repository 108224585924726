import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import Login from './pages/Login';
import { isLoggedIn } from './public-services/black-api/black-api';

function App() {  

  const checkLogin = async () => {
    let status = await isLoggedIn();
    if (!status && window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }
  checkLogin();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default App;
