import React, { useEffect, useState } from "react";
import {
  getMyId,
  createAmbulanceChecklist,
  isLoggedIn,
  getSections,
  createAmbulanceForm,
  getMedicalServicesEmployees
} from "../public-services/black-api/black-api";

export const Home = () => {
  const [sectionCount, setSectionCount] = useState(0);
  const [subSectionCount, setSubSectionCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [data, setData] = useState<any>();
  const [answer, setAnswer] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormView, setIsFormView] = useState(true);
  const [formErrorMsg, setFormErrorMsg] = useState(false);
  const [fileRef, setFileRef] = useState<any>({});
  const [isAnsweredFilled, setIsAnsweredFilled] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  useEffect(() => {
    checkLogin();
    getData();
    // employeesList();
    employeesListSerializer();
  }, []);

  const employeesListSerializer = async () => {
    if (employees.length == 0) {
      const _employees = await getMedicalServicesEmployees();
      _employees.map((employee: any) => {
        // console.log("emp", employee);
        setEmployees((prevEmployees: any) => ([...prevEmployees, { name: employee.name, id: employee.id }]));
      })
      // console.log("Ser", _employees);
      return _employees;
    }
  }
  const employeesOptions = () => {
    return (
      <>
      
      <option value="">-- Select Person --</option>

      {employees.map((employee: any) => (
        <option value={employee.id}>{employee.name}</option>
      ))}
      
      </>
    )

  }
  const checkLogin = async () => {
    let status = await isLoggedIn();
    if (!status) {
      window.location.href = "/login";
    }
  };

  const getData = async () => {
    let _data: any = await getSections();
    setData(_data);
  };
  const handleAnswer = (id: any, title: any, e: any,) => {

    if (e.target.type === "file") {
      const file = e.target.files;

      if (fileRef[id]) {
          setFileRef((prevAnswer: any) => ({
              ...prevAnswer,
              [id]: { value: [...prevAnswer[id].value, ...file], title: title }
          }));
      } else {
          setFileRef((prevAnswer: any) => ({
              ...prevAnswer,
              [id]: { value: file, title: title }
          }));
      }
    }

    else if (e.target.type == "checkbox") {
      if (e.target.checked) {
        if (answer[id]) {
          setAnswer((prevAnswer: any) => ({
            ...prevAnswer,
            [id]: [...prevAnswer[id], { value: e.target.value, title: title }],
          }));
        } else {
          setAnswer((prevAnswer: any) => ({
            ...prevAnswer,
            [id]: [{ value: e.target.value, title: title }],
          }));
        }
      }

      else {
        const newValues = answer[id].filter((item: any) => item.value !== e.target.value);
        setAnswer((prevAnswer: any) => ({ ...prevAnswer, [id]: newValues }));
      }
    } else {
      setAnswer((prevAnswer: any) => ({ ...prevAnswer, [id]: { value: e.target.value, title: title } }));
    }
    // console.log('ANSWER', answer);
    // console.log("file", fileRef);
  };

  const handleFormData = (id: any, e: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [id]: e.target.value,
    }));

    // console.log(formData);
  };

  const checkDefaultValue = (id: any, value: any) => {
    if (answer[id] == value) {
      return true;
    } else {
      return false;
    }
  };

  const questionMaker = (
    type: any,
    question_options: any,
    question_title: any,
    question_id: any,
    is_required?: any
  ) => {
    return (
      <>
        <div className="mx-auto my-6  transition-all ease-in-out duration-300">
          <div className="p-4 m-auto ">
            <h2 className=" text-2xl">
              {question_title || "Note"}{" "}
              {is_required ? <strong className="text-red-400">*</strong> : ""}
            </h2>
            {type === "radio" ? (
              <>
                {question_options?.map((option: any) => (
                  <label
                    className={`p-2 ${answer[question_id]?.value === option?.value
                      ? "bg-blue-700 text-white border-blue-950"
                      : "border-black"
                      } my-2 flex flow-row border-2  rounded-md `}
                  >
                    <div>
                      <input
                        className="w-6 h-6 my-auto hidden "
                        type="radio"
                        name={question_id}
                        id={option?.text}
                        value={option?.value}
                        onChange={(e) => handleAnswer(question_id, question_title, e)}
                        checked={checkDefaultValue(question_id, option?.value)}
                      />
                      <label className="mx-2 text-lg   ">{option?.text}</label>
                    </div>
                  </label>
                ))}
              </>
            ) : type == "checkbox" ? (
              <>
                {question_options?.map((option: any) => (
                  <div className="p-2 mx-auto my-2 flex">
                    <input
                      className="w-6 h-6 my-auto"
                      type="checkbox"
                      name={question_id}
                      id={option?.text}
                      value={option?.value}
                      onChange={(e) => handleAnswer(question_id, question_title, e)}
                      checked={answer[question_id]?.includes(option?.value)}
                    />
                    <label className="mx-2 text-lg">{option?.text}</label>
                  </div>
                ))}
              </>
            ) : type == "file" ? (
              <>
                <input
                  id="file"
                  type="file"
                  multiple={true}
                  accept="image/*, video/*"
                  name={question_id}
                  onChange={(e) => handleAnswer(question_id, question_title, e)}
                  className="hidden"
                />
                <label
                  htmlFor="file"
                  className="flex items-center justify-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md shadow-sm cursor-pointer hover:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  <span>Upload</span>
                </label>

                {fileRef[question_id]?.value && (
                  <div className="mt-2">
                    {Object.values(fileRef[question_id]?.value).map((file: any) => (
                      <div className="flex justify-between items-center p-2 border rounded-lg my-2">
                        <div className="flex items-center">
                          <span className="text-sm">{file.name}</span>
                        </div>
                        <button
                          className="text-red-500 hover:text-red-700"
                          onClick={() => handleDeleteFile(question_id, file)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                <input
                  className="p-2 border rounded-lg border-black"
                  type={type}
                  name={question_id}
                  id={question_id}
                  onChange={(e) => handleAnswer(question_id, question_title, e)}
                  value={answer[question_id]?.value || ""}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const setquestion = (q?: any) => {
    let questions;
    let subsection_title =
      data[sectionCount]?.expand?.section_details[subSectionCount]?.subsection_title;
    // let subsection_is_required =
    //   data[sectionCount]?.expand?.section_details[subSectionCount]?.subsection_title;
    if (q) {
      questions = q;

      return (
        <>
          {questions?.map((question: any) => (
            <>
              {questionMaker(
                question.type,
                question.options,
                question.title,
                question.id,
                question.is_required
              )}
              {question.has_dependent &&
                answer[question.id]?.value == question.dependent_trigger ? (
                <>{setquestion(question?.expand?.dependent_question)}</>
              ) : null}
            </>
          ))}
        </>
      );
    } else {
      questions = data[sectionCount]?.expand?.section_details[subSectionCount];

      return (
        <>
          <h1 className="p-4 m-auto text-center text-xl">
            {subsection_title || ""} { }
          </h1>

          <h2>
            {isAnsweredFilled ? (
              ""
            ) : (
              <h1 className="p-4 m-auto text-center text-xl text-red-600">
                *Please fill all the fields*
              </h1>
            )}
          </h2>
          {questions?.expand?.subsection_question?.map((question: any) => (
            <>
              {questionMaker(
                question.type,
                question.options,
                question.title,
                question.id,
                question.is_required
              )}
              {question.has_dependent &&
                answer[question.id]?.value == question.dependent_trigger ? (
                <>{setquestion(question.expand.dependent_question)}</>
              ) : null}
            </>
          ))}
        </>
      );
    }
  };

  const handleDeleteFile = (id: any, file: any) => {
    const newFiles = Object.values(fileRef[id].value).filter(
      (item: any) => item !== file
    );

    // console.log(newFiles);
    setFileRef((prevAnswer: any) => ({ ...prevAnswer, [id]: { value: newFiles, title: fileRef[id]?.title } }));
  };

  const areAllQuestionsAnswered = (_question?: any) => {
    let questions;
    if (_question) {
      questions = _question;
    } else {
      questions =
        data[sectionCount]?.expand?.section_details[subSectionCount]
          ?.expand?.subsection_question;
    }

    let isAnswered = true;
    questions?.map((question: any) => {
      if(question.type == "file"){
        if(fileRef[question.id]?.value == undefined || fileRef[question.id]?.value == null || fileRef[question.id]?.value == ""){
          isAnswered = false;
        }
      }
      else{

        if (
          answer[question.id]?.value == undefined ||
          answer[question.id]?.value == null ||
          answer[question.id]?.value == ""
        ) {
          isAnswered = false;
        } else {
          if (
            question.has_dependent &&
            answer[question.id]?.value == question.dependent_trigger
          ) {
            if (areAllQuestionsAnswered(question.expand?.dependent_question)) {
              isAnswered = true;
            } else {
              isAnswered = false;
            }
          }
        }
      }
    });
    return isAnswered;
  };

  const handelNextQuestion = () => {
    if (areAllQuestionsAnswered()) {
      setIsAnsweredFilled(true);
      if (subSectionCount < data[sectionCount]?.expand?.section_details?.length - 1) {
        setSubSectionCount(subSectionCount + 1);
        setQuestionCount(0);
      } else if (
        subSectionCount == data[sectionCount]?.expand?.section_details?.length - 1 &&
        sectionCount < data?.length - 1
      ) {
        setSectionCount(sectionCount + 1);
        setSubSectionCount(0);
        setQuestionCount(0);
      }
      window.scrollTo(0, 0);
    } else {
      setIsAnsweredFilled(false);
    }

    window.scrollTo(0, 0);
  };

  const handelPrevQuestion = () => {
    // if (questionCount > 0) {
    //     setQuestionCount(questionCount - 1);
    // }
    if (subSectionCount > 0) {
      setSubSectionCount(subSectionCount - 1);
      setQuestionCount(
        data[sectionCount]?.expand?.section_details[subSectionCount - 1]
          ?.expand?.subsection_question?.length - 1
      );
    } else if (sectionCount > 0) {
      setSectionCount(sectionCount - 1);
      const lastSubsectionIndex =
        data[sectionCount - 1]?.expand?.section_details?.length - 1;
      setSubSectionCount(lastSubsectionIndex);
      setQuestionCount(
        data[sectionCount - 1]?.expand?.section_details[lastSubsectionIndex]
          ?.expand?.subsection_question?.length - 1
      );
    }
    window.scrollTo(0, 0);
  };

  const handelSubmit = async () => {
    // console.log(formData);
    // console.log(fileRef);
    setIsLoading(true);
    let checklist_data = new FormData();
    if (JSON.stringify(fileRef) != "{}") {
      Object.keys(fileRef).map((key: any) => {

        for (let i = 0; i < fileRef[key].value.length; i++) {
          checklist_data.append("attachment", fileRef[key].value[i]);
        }

      });

    }
    // console.log("fileRef after submit:", checklist_data.values());

    checklist_data.append("created_by", await getMyId());
    checklist_data.append("checklist_answer", JSON.stringify(answer));

    // console.log(checklist_data.values());

    await createAmbulanceForm(JSON.stringify(formData))
      .then(async (res: any) => {
        // console.log(res);
        // console.log(res.id);
        checklist_data.append("checklist_form_id", res.id);
        await createAmbulanceChecklist(checklist_data)
          .then((res: any) => {
            setIsLoading(false);
            window.location.reload();
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const setNextQuestionBtn = () => {
    if (
      questionCount <
      data[sectionCount]?.expand?.section_details[subSectionCount]?.expand?.subsection_question
        ?.length -
      1
    ) {
      return true;
    } else if (
      questionCount ==
      data[sectionCount]?.expand?.section_details[subSectionCount]?.expand?.subsection_question?.length -
      1 &&
      subSectionCount < data[sectionCount]?.expand?.section_details?.length - 1
    ) {
      return true;
    } else if (
      questionCount ==
      data[sectionCount]?.expand?.section_details[subSectionCount].expand?.subsection_question?.length -
      1 &&
      subSectionCount == data[sectionCount]?.expand?.section_details?.length - 1 &&
      sectionCount < data?.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setPrevQuestionBtn = () => {
    if (questionCount > 0) {
      return true;
    } else if (questionCount == 0 && subSectionCount > 0) {
      return true;
    } else if (questionCount == 0 && subSectionCount == 0 && sectionCount > 0) {
      return true;
    } else {
      return false;
    }
  };

  const setSubmitBtn = () => {
    if (
      questionCount ==
      data[sectionCount]?.expand?.section_details[subSectionCount]
        ?.expand?.subsection_question?.length -
      1 &&
      subSectionCount == data[sectionCount]?.expand?.section_details?.length - 1 &&
      sectionCount == data?.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  // ------------------------------------------------------------------------------------------------//

  // ------------------------------------------------------------------------------------------------//

  const isFormFilled = () => {
    if (
      formData.ambulance_no &&
      formData.ambulance_type &&
      formData.ambulance_level &&
      formData.mileage &&
      formData.dispatcher &&
      formData.team_leader 
    ) {
      setIsFormView(false);
      window.scrollTo(0, 0);
      return true;
    } else {
      setFormErrorMsg(true);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const setFormError = () => {
    if (!formData.ambulance_no) {
      return "*Ambulance No is required*";
    } else if (!formData.ambulance_type) {
      return "*Ambulance Type is required*";
    } else if (!formData.ambulance_level) {
      return "*Ambulance Level is required*";
    } else if (!formData.mileage) {
      return "*Mileage is required*";
    } else if (!formData.dispatcher) {
      return "*Dispatcher is required*";
    } else if (!formData.team_leader) {
      return "*Team Leader is required*";
    } 
  };

  const setAmbulanceInfoForm = () => {
    return (
      <>
        <div className="p-4">
          <div className="p-2 m-auto flex justify-center">
            <h1 className="text-3xl mx-auto">Ambulance Info </h1>
          </div>

          <div className="p-2 m-auto flex justify-center">
            <h1 className="text-xl mx-auto text-red-600">
              {formErrorMsg ? setFormError() : ""}
            </h1>
          </div>

          <form className="flex flex-wrap rounded-sm shadow-md bg-gray-50">
            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Ambulance No. <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <input
                  onChange={(e) => handleFormData("ambulance_no", e)}
                  type="text"
                  name="ambulance_no"
                  id="ambulance_no"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Ambulance Type <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2 space-x-5">
                <div className="my-2 space-x-3">
                  <input
                    onChange={(e) => handleFormData("ambulance_type", e)}
                    type="radio"
                    value="ALS"
                    name="ambulance_type"
                    id="ALS"
                    className="peer hidden"
                  />
                  <label htmlFor="ALS" 
                  className={`inline-flex items-center justify-center h-10 gap-2 px-5 text-sm 
                  font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none 
                  whitespace-nowrap border-sky-500  focus:border-sky-700 focus:text-sky-700 disabled:cursor-not-allowed 
                  disabled:border-sky-300 disabled:text-sky-300 disabled:shadow-none ${formData?.ambulance_type == "ALS" ? "bg-sky-500 text-white" : "text-sky-500 hover:border-sky-600 hover:text-sky-600"}`}>
                    ALS
                    </label>
                  <input
                    onChange={(e) => handleFormData("ambulance_type", e)}
                    type="radio"
                    value="BLS"
                    name="ambulance_type"
                    id="BLS"
                    className="peer hidden"
                  />
                  <label htmlFor="BLS"
                   className={`inline-flex items-center justify-center h-10 gap-2 px-5 text-sm 
                   font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none 
                   whitespace-nowrap border-sky-500  focus:border-sky-700 focus:text-sky-700 disabled:cursor-not-allowed 
                   disabled:border-sky-300 disabled:text-sky-300 disabled:shadow-none ${formData?.ambulance_type == "BLS" ? "bg-sky-500 text-white" : "text-sky-500 hover:border-sky-600 hover:text-sky-600"}`}>
                    BLS</label>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Ambulance Level <strong className="text-red-400">*</strong>
              </label>
              <div>
                <div className="my-2 space-x-3">
                  <input
                    onChange={(e) => handleFormData("ambulance_level", e)}
                    type="radio"
                    value="Type 1"
                    name="ambulance_level"
                    id="type1"
                    className="peer hidden"
                  />
                  <label htmlFor="type1"
                   className={`inline-flex items-center justify-center h-10 gap-2 px-5 text-sm 
                   font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none 
                   whitespace-nowrap border-sky-500  focus:border-sky-700 focus:text-sky-700 disabled:cursor-not-allowed 
                   disabled:border-sky-300 disabled:text-sky-300 disabled:shadow-none ${formData?.ambulance_level == "Type 1" ? "bg-sky-500 text-white" : "text-sky-500 hover:border-sky-600 hover:text-sky-600"}`}
                  >Type 1</label>
                  <input
                    onChange={(e) => handleFormData("ambulance_level", e)}
                    type="radio"
                    value="Type 2"
                    name="ambulance_level"
                    id="type2"
                    className="peer hidden"
                  />
                  <label
                   className={`inline-flex items-center justify-center h-10 gap-2 px-5 text-sm 
                   font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none 
                   whitespace-nowrap border-sky-500  focus:border-sky-700 focus:text-sky-700 disabled:cursor-not-allowed 
                   disabled:border-sky-300 disabled:text-sky-300 disabled:shadow-none ${formData?.ambulance_level == "Type 2" ? "bg-sky-500 text-white" : "text-sky-500 hover:border-sky-600 hover:text-sky-600"}`}
                  htmlFor="type2">Type 2</label>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Mileage (km) <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <input
                  onChange={(e) => handleFormData("mileage", e)}
                  type="number"
                  name="mileage"
                  id="mileage"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Dispatcher <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <select
                  name="Dispatcher"
                  id="Dispatcher"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) => handleFormData("dispatcher", e)}
                >
                  {employeesOptions()}
                </select>

                {/* <input
                  onChange={(e) => handleFormData("dispatcher", e)}
                  type="text"
                  name="dispatcher"
                  id="dispatcher"
                  className=""
                /> */}
              </div>
            </div>

            {/* <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Dispatcher Badge No. <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <input
                  onChange={(e) => handleFormData("dispatcher_badge_no", e)}
                  type="text"
                  name="dispatcher_badge_no"
                  id="dispatcher_badge_no"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div> */}

            <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Team Leader <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <select
                  name="Dispatcher"
                  id="Dispatcher"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  defaultValue={""}
                  onChange={(e) => handleFormData("team_leader", e)}
                >
                  {employeesOptions()}
                </select>
              </div>
            </div>

            {/* <div className="w-full sm:w-1/2 p-4">
              <label
                htmlFor="name"
                className="block text-xl font-medium text-gray-700"
              >
                Team Leader Badge No.{" "}
                <strong className="text-red-400">*</strong>
              </label>
              <div className="my-2">
                <input
                  onChange={(e) => handleFormData("team_leader_badge_no", e)}
                  type="text"
                  name="team_leader_badge_no"
                  id="team_leader_badge_no"
                  className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div> */}
          </form>

          <div>
            <div className="p-2 m-auto flex justify-center">
              <button
                className={`bg-blue-500 w-2/5  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2`}
                onClick={() => isFormFilled()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isFormView ? <>{setAmbulanceInfoForm()}</> : null}

      {data && !isFormView ? (
        <>
          <div>
            <div className="p-2 mx-auto my-6 flex w-full">
              <button
                className={`bg-slate-500 w-full mx-auto hover:bg-slate-700 text-white text-3xl font-bold py-2  rounded `}
                onClick={() => setIsFormView(true)}
              >
                Ambulance Info
              </button>
            </div>
          </div>

          <div className="p-2 text-center">
            <h1 className="text-3xl mx-auto">
              {data[sectionCount]?.section_title || "Loading"}
            </h1>
          </div>

          <div>
            <div className="p-2 m-auto flex">
              <button
                className={`bg-blue-500 w-2/5 mr-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 ${setPrevQuestionBtn() ? "" : "hidden"
                  }`}
                onClick={handelPrevQuestion}
              >
                Previous
              </button>
              <button
                className={`bg-blue-500 w-2/5 ml-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 ${setNextQuestionBtn() ? "" : "hidden"
                  }`}
                onClick={handelNextQuestion}
              >
                Next
              </button>
            </div>

            {setquestion()}

            <div className="p-2 m-auto flex">
              <button
                className={`bg-blue-500 w-2/5 mr-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 ${setPrevQuestionBtn() ? "" : "hidden"
                  }`}
                onClick={handelPrevQuestion}
              >
                Previous
              </button>
              <button
                className={`bg-blue-500 w-2/5 ml-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 ${setNextQuestionBtn() ? "" : "hidden"
                  }`}
                onClick={handelNextQuestion}
              >
                Next
              </button>
            </div>

            <div className="p-2 m-auto flex flex-wrap justify-center">
              <button
                className={` flex justify-center bg-green-500 w-2/5 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none ${setSubmitBtn() ? "" : "hidden"
                  }`}
                onClick={handelSubmit}
                disabled={isLoading || !areAllQuestionsAnswered()}
              >
                {isLoading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                )}
                Submit
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
