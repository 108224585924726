/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  CreateAccount,
  login,
  resetPassword,
  getDepartments,
  isLoggedIn,
} from "../public-services/black-api/black-api";
import { get } from "http";

const Login = () => {


  const [Tab, setTab] = React.useState("Login");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [createAccountErrorMessages, setCreateAccountErrorMessages] =
    React.useState<any>("Email must be a valid email");
  const [data, setData] = React.useState<any>();
  const [positionData, setPositionData] = React.useState<any>([

  ]);

  useEffect(() => {
    checkLogin();
    getDepartmentsList();
  }, []);
  const checkLogin = async () => {
    let status = await isLoggedIn();
    if (status) {
      // window.location.href = "/";
    }
  };
  const DepartmentsSerializer = (departments: any) => {
    let departmentsList: any = [];
    let i = 0;
    departments?.data?.items?.map((department: any) => {
      departmentsList[i] = {
        name: department.name,
        id: department.id,
      };
      i++;
    });
    return departmentsList;
  }
  const getDepartmentsList = async () => {
    const departments = await getDepartments();
    console.log("Not serialized", departments);
    let departmentsList = DepartmentsSerializer(departments);
    console.log("Serialized", departmentsList);
    setPositionData(departmentsList);
  };


  const handleLogin = async () => {
    login(email, password)
      .then((res: any) => {
        if (!(res?.status?.slice(0, 1) == 4)) {
          window.location.href = "/";
        } else {
          setError(true);
        }
      })
      .catch((err: any) => {
        setError(true);
      });
  };

  const handleCreateAccount = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
    console.log(data);
  };

  const emailValidation = (value: string) => {
    if (/^[A-Z0-9._%+-]+@lazem\.sa$/i.test(value)) {
      return true;
    }
    return false;
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const setErrorMsg = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center mt-4">
          <p className="text-lg text-red-500">Invalid email or password</p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const isCreateAccountFilled = () => {
    if (
      data?.Email &&
      data?.password == data?.passwordConfirm &&
      data?.department
    ) {
      return true;
    } else if (data.password != data.passwordConfirm) {
      setCreateAccountErrorMessages(
        "Password and Confirm Password must be the same"
      );
      return false;
    } else if (data.password.length < 8) {
      setCreateAccountErrorMessages("Password must be at least 8 characters");
      return false;
    } else if (!emailValidation(data.Email)) {
      setCreateAccountErrorMessages("Email must be a valid email");
      return false;
    } else setCreateAccountErrorMessages("Please fill all fields");
  };

  const handelSubmit = async () => {
    if (isCreateAccountFilled()) {
      if (data.department == "Medical Services") {
        data.department = positionData?.medical_services;
      } else if (data.department == "Customer Success") {
        data.department = positionData?.customer_success;
      } else if (data.department == "General Operations") {
        data.department = positionData?.operations;
      }
      let data_to_submit = {
        email: data.Email,
        password: data.password,
        passwordConfirm: data.password,
        department: data.department.id,
        username: data.Email?.split("@")[0] || "",
        emailVisibility: true,
        position: data.department.name,
        role: data.department.roul,
      };

      CreateAccount(data_to_submit)
        .then((res: any) => {
          if (!(res?.status?.slice(0, 1) == 4)) {
            window.location.reload();
          } else {
            setError(true);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setError(true);
        });
    } else {
      setError(true);
    }
  };

  const DepartmentsRadio = () => {

    return (
      positionData?.map((department: any) => {
        return (
          <div>
            <input
              type="radio"
              value={department?.id}
              name="department"
              onChange={(e: any) => handleCreateAccount(e)}
              className="peer hidden"
              id={department.id}
            />
            <label htmlFor={department.id}
            className={`inline-flex items-center justify-center h-10 gap-2 px-5 text-sm 
            font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none 
            whitespace-nowrap border-sky-500  focus:border-sky-700 focus:text-sky-700 disabled:cursor-not-allowed 
            disabled:border-sky-300 disabled:text-sky-300 disabled:shadow-none ${data?.department == department?.id ? "bg-sky-500 text-white" : "text-sky-500 hover:border-sky-600 hover:text-sky-600"}`}>
            {department?.name}
            </label> 
          </div>
        )
      })
    )
  }

  return Tab === "Login" ? (
    <>
      <div className="p-32   border-2 border-black shadow-md rounded-md w-[50%] mx-auto my-10 bg-slate-50">
        <div className="flex flex-col items-center justify-center">
          <img
            src="https://lazemms.sa/_next/static/media/LogoDark.0670a20c.png"
            className="w-32 h-32 mb-3"
          />
          <h1 className="text-2xl font-bold text-slate-900">Login</h1>
          <p className="text-sm text-slate-900">Please login to continue</p>
        </div>

        {setErrorMsg()}

        <div className="flex flex-col items-center justify-center mt-4">
          <input
            type="text"
            placeholder="Email"
            className="p-2  w-[100%] border border-slate-900 rounded-sm"
            onChange={(e: any) => handleEmailChange(e)}
          />
          <input
            type="password"
            placeholder="Password"
            className="p-2 w-[100%] border border-slate-900 rounded-sm mt-2"
            onChange={(e: any) => handlePasswordChange(e)}
          />
        </div>

        <div className="flex flex-col items-center justify-center mt-4">
          <button
            type="submit"
            className=" p-2 w-[100%] bg-blue-600 text-white rounded-sm cursor-pointer"
            onClick={handleLogin}
          >
            Login
          </button>
          <div className="mt-5">
            <a
              onClick={() => setTab("Rest")}
              className=" p-2 w-[100%] underline rounded-sm cursor-pointer"
            >
              Forget Password
            </a>
            <a
              onClick={() => {
                setTab("Create Account");
                getDepartmentsList();
              }}
              className=" p-2 underline cursor-pointer"
            >
              Create an account
            </a>
          </div>
        </div>
      </div>
    </>
  ) : Tab === "Create Account" ? (
    <>
      <div className="p-32   border-2 border-black shadow-md rounded-md w-[50%] mx-auto my-10 bg-slate-50">
        <div className="flex flex-col items-center justify-center">
          <img
            src="https://lazemms.sa/_next/static/media/LogoDark.0670a20c.png"
            className="w-32 h-32 mb-3"
          />
          <h1 className="text-2xl font-bold text-slate-900">
            Create an Account
          </h1>
          {/* <p className="text-sm text-slate-900">Please login to continue</p> */}
        </div>

        <div className="flex flex-col items-center justify-center mt-4">
          <h3 className="text-lg text-red-500">{createAccountErrorMessages}</h3>
        </div>

        <div className="flex flex-col items-center justify-center mt-4 space-y-4">
          <input
            type="text"
            name="Email"
            placeholder="Email"
            className="p-2  w-[100%] border border-slate-900 rounded-sm"
            onChange={(e: any) => {
              if (emailValidation(e.target.value)) {
                console.log("Valid");
                handleCreateAccount(e);
              } else {
                console.log("Not Valid");
              }
            }}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="p-2  w-[100%] border border-slate-900 rounded-sm"
            onChange={(e: any) => handleCreateAccount(e)}
          />
          <input
            type="password"
            name="passwordConfirm"
            placeholder="Confirm Password"
            className="p-2  w-[100%] border border-slate-900 rounded-sm"
            onChange={(e: any) => handleCreateAccount(e)}
          />
          <h2 className="text-xl font-bold my-2 text-slate-900">Department</h2>
          <div className="space-x-4 my-3 flex flex-wrap">
          {DepartmentsRadio()}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center text-center space-y-4 mt-3">
          <a
            onClick={() => handelSubmit()}
            className=" p-2 w-[100%] bg-blue-600 text-white rounded-sm cursor-pointer"
          >
            Submit
          </a>
          <div>
            <a
              onClick={() => setTab("Rest")}
              className=" p-2 w-[100%] underline rounded-sm cursor-pointer"
            >
              Forgot Password
            </a>
            <a
              onClick={() => setTab("Login")}
              className=" p-2 w-[100%] underline rounded-sm cursor-pointer"
            >
              Have an Account
            </a>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="p-32   border-2 border-black shadow-md rounded-md w-[60%] mx-auto my-10 bg-slate-50">
        <div className="flex flex-col items-center justify-center">
          <img
            src="https://lazemms.sa/_next/static/media/LogoDark.0670a20c.png"
            className="w-32 h-32 mb-3"
          />
          <h1 className="text-2xl font-bold text-slate-900">
            Reset Your Password
          </h1>
          <p className="text-sm text-slate-900">Please enter your email</p>
        </div>

        {setErrorMsg()}

        <div className="flex flex-col  items-center justify-center mt-4">
          <input
            type="text"
            placeholder="Email"
            className="w-11/12 p-2 border border-slate-900 rounded-sm"
            onChange={(e: any) => handleEmailChange(e)}
          />
        </div>
        <div className="flex flex-col  items-center justify-center text-center space-y-4 mt-3">
          <a
            type="submit"
            onClick={() => resetPassword(email)}
            className=" p-2 w-[90%] bg-blue-600 text-white rounded-sm cursor-pointer"
          >
            Rest
          </a>
          <div className="wfu">
            <a
              onClick={() => setTab("Create Account")}
              className=" p-2 w-[100%] underline rounded-sm cursor-pointer"
            >
              Create an Account
            </a>
            <a
              onClick={() => setTab("Login")}
              className=" p-2 w-[100%] underline rounded-sm cursor-pointer"
            >
              Have an Account
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
